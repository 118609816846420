/**
 * Owl Carousel v2.3.4
 * Copyright 2013-2018 David Deutsch
 * Licensed under: SEE LICENSE IN https://github.com/OwlCarousel2/OwlCarousel2/blob/master/LICENSE
 */
/*
 *  Owl Carousel - Core
 */

.owl-carousel {
  display: none;
  width: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1; }
  .owl-carousel .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */ }
  .owl-carousel .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0; }
  .owl-carousel .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px); }
  .owl-carousel .owl-wrapper,
  .owl-carousel .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0); }
  .owl-carousel .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none; }
  .owl-carousel .owl-item img {
    display: block;
    width: 100%; }
  .owl-carousel .owl-nav.disabled,
  .owl-carousel .owl-dots.disabled {
    display: none; }
  .owl-carousel .owl-nav .owl-prev,
  .owl-carousel .owl-nav .owl-next,
  .owl-carousel .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel .owl-nav button.owl-prev,
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel button.owl-dot {
    background: none;
    color: inherit;
    border: none;
    padding: 0 !important;
    font: inherit; }
  .owl-carousel.owl-loaded {
    display: block; }
  .owl-carousel.owl-loading {
    opacity: 0;
    display: block; }
  .owl-carousel.owl-hidden {
    opacity: 0; }
  .owl-carousel.owl-refresh .owl-item {
    visibility: hidden; }
  .owl-carousel.owl-drag .owl-item {
    -ms-touch-action: pan-y;
        touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none; }
  .owl-carousel.owl-grab {
    cursor: move;
    cursor: grab; }
  .owl-carousel.owl-rtl {
    direction: rtl; }
  .owl-carousel.owl-rtl .owl-item {
    float: right; }

/* No Js */
.no-js .owl-carousel {
  display: block; }

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both; }

.owl-carousel .owl-animated-in {
  z-index: 0; }

.owl-carousel .owl-animated-out {
  z-index: 1; }

.owl-carousel .fadeOut {
  animation-name: fadeOut; }

@keyframes fadeOut {
  0% {
    opacity: 1; }
  100% {
    opacity: 0; } }

/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out; }

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
			This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
			calculation of the height of the owl-item that breaks page layouts
		 */ }
  .owl-carousel .owl-item .owl-lazy {
    opacity: 0;
    transition: opacity 400ms ease; }
  .owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
    max-height: 0; }
  .owl-carousel .owl-item img.owl-lazy {
    transform-style: preserve-3d; }

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000; }

.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease; }

.owl-carousel .owl-video-play-icon:hover {
  -ms-transform: scale(1.3, 1.3);
      transform: scale(1.3, 1.3); }

.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none; }

.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease; }

.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%; }

/*UDUCAT:GLP-2: 2025-02-14:1539_29*/
:root {
  --udu__less-version: "2025-02-14:1539_29";
}
._ .category-list {
  display: none;
}
._ .category-list.owl-carousel {
  display: block;
}
._ ul.nivoSlider {
  display: none;
  height: auto !important;
  list-style: none;
  position: relative;
}
._ ul.nivoSlider._done {
  display: block;
}
._ ul.nivoSlider .udu__owl-items-count-0 {
  min-height: 200px;
}
._ .main .center-part.for-slider {
  max-width: 1920px;
}
._ .main .center-part.for-slider .nivoSlider {
  margin: 0 auto;
  max-width: 1920px !important;
  padding: 0 0 10px 0 !important;
}
._ .main .center-part.for-slider .nivoSlider li {
  overflow: hidden;
}
._ .main .center-part.for-slider .nivoSlider li .owl-nav .owl-prev,
._ .main .center-part.for-slider .nivoSlider li .owl-nav .owl-next {
  background-color: #34373c !important;
  background-image: url(./img/arrow-medium.png) !important;
  background-position: -12px 7px !important;
  background-repeat: no-repeat !important;
  cursor: pointer;
  display: block;
  height: 70px;
  margin-top: -35px;
  opacity: 1;
  position: absolute;
  top: 50%;
  transition: all 0.4s ease-in-out;
  width: 35px;
  z-index: 1000;
}
._ .main .center-part.for-slider .nivoSlider li .owl-nav .owl-next {
  background-position: -10px -50px !important;
  border-radius: 5px 0 0 5px;
  right: -40px;
}
._ .main .center-part.for-slider .nivoSlider li .owl-nav .owl-prev {
  border-radius: 0 5px 5px 0;
  left: -40px;
}
._ .main .center-part.for-slider .nivoSlider li .owl-carousel {
  position: relative;
}
._ .main .center-part.for-slider .nivoSlider li .owl-carousel:hover .owl-prev {
  left: -2px;
}
._ .main .center-part.for-slider .nivoSlider li .owl-carousel:hover .owl-next {
  right: -2px;
}
._ .main .center-part.for-slider .nivoSlider li .owl-carousel .owl-dots {
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  padding: 25px 0;
  position: absolute;
  right: 0;
}
._ .main .center-part.for-slider .nivoSlider li .owl-carousel .owl-dots .owl-dot {
  background-color: #ffffff;
  border-radius: 50%;
  height: 10px;
  margin: 0 4px;
  padding: 0 !important;
  width: 10px;
}
._ .main .center-part.for-slider .nivoSlider li .owl-carousel .owl-dots .owl-dot.active,
._ .main .center-part.for-slider .nivoSlider li .owl-carousel .owl-dots .owl-dot:hover {
  background-color: #146cda;
  transition: all 0.3s ease-in-out;
}
._ .main .center-part.for-slider .nivoSlider li .owl-carousel .owl-item {
  cursor: pointer;
  width: 100%;
}
@media (max-width: 1220px) {
  ._ #slider {
    width: 100% !important;
  }
}
@media (max-width: 1040px) {
  ._ #slider {
    width: 100% !important;
  }
}
@media (max-width: 760px) {
  ._ #slider {
    display: block !important;
  }
}
